import React, { useState, useEffect, useMemo } from "react";
import Nav from "components/Nav";
import { useEnforcerAuth } from "store/EnforcerAuth";
import { checkEnforcerToken } from "components/checkToken";
import { useNavigate } from 'react-router-dom';
import Footer from "components/Footer";

function EnforcementGuide() {
  const getAuth = useEnforcerAuth((state) => state.auth);
  const token = getAuth.token;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  }


  return (
    <div className="min-h-screen flex flex-col">
      <Nav />
      <div className="flex-grow">
        <div className="text-xl lg:w-[60%] w-[80%] mx-auto leading-loose">
          <button className="bg-gray-100 hover:bg-gray-200 text-black font-bold py-1 px-4 rounded-lg shadow transition duration-200 ease-in-out flex items-center gap-2 mb-4 mt-4" onClick={goBack}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Go Back
          </button>

          <div className="text-center text-2xl font-bold mb-6">
            {/* Motor Vehicle Information Verifier <span className="text-red-600 font-bold">(V-Check)</span> */}
            <div className="text-xl mt-2">Operational Guidelines on use of V-Check Application</div>
          </div>

          <div className="text-justify space-y-4">
            <div>
              <h2 className="font-bold mb-2">Introduction:</h2>
              <p>
              The Motor Vehicle Information Verifier <span className="text-red-600 font-bold">(V-Check)</span> mobile app is a digital tool designed to enhance
                the capability to efficiently and effectively verify vehicle information in real-time. This operational
                guideline outlines the procedures and protocols for using the <span className="text-red-600 font-bold">V-Check</span> mobile app to support
                investigative and operational efficiency aimed at enhancing the safety and security of lives and
                property of persons, prevent and detect crimes and strengthen national security.
              </p>
            </div>

            <div>
              <h2 className="font-bold mb-2">Objective:</h2>
              <p>
                The objective of this guideline is to ensure the use of <span className="text-red-600 font-bold">V-Check</span> mobile app in a standardized and
                efficient manner to verify vehicle information, thereby improving vehicle security, maintaining order
                and safety on the roads and reducing the incidence of vehicle-related crimes.
              </p>
            </div>

            <div>
              <h2 className="font-bold mb-2">Equipment Requirements:</h2>
              <ul className="list-disc ml-6">
                <li>Smartphone or tablet with internet connectivity</li>
                <li><span className="text-red-600 font-bold">V-Check</span> mobile app installed on the device</li>
                <li>Valid login credentials (Username and Password)</li>
              </ul>
            </div>

            <div>
              <h2 className="font-bold mb-2">Operational Procedures:</h2>
              <ul className="list-decimal ml-6">
                <li>Activate app on your device. Website is <a href="https://npfcmr.ng/vcheck" className="text-blue-600">https://npfcmr.ng/vcheck</a></li>
                <li>Login to the <span className="text-red-600 font-bold">V-Check</span> mobile app using valid Username and Password</li>
                <li>Input vehicle's Licence Plate Number or Chassis Number/VIN</li>
                <li>Click <span className="font-bold">Search</span> to retrieve the vehicle's information</li>
                <li>Vehicle's information status, including ownership are provided real-time for verification and informed decision-making</li>
                <li>All data retrieved and analyzed through the app shall be treated with absolute confidentiality</li>
              </ul>
            </div>

            <div>
              <h2 className="font-bold mb-2">Vehicle Status:</h2>
              <ul className="list-disc ml-6">
                <li><span className="font-semibold">Not Found</span> – Politely approach the owner/operator and highlight benefits of having vehicle
                  information with the Police. Advise owner to visit <a href="https://cmris.npf.gov.ng" className="text-blue-600">https://cmris.npf.gov.ng</a> to apply.</li>
                <li><span className="font-semibold">Conflict</span> – Another vehicle bears the same Licence Plate Number or Chassis Number.
                  Contact CMR Information Centre for enquiry.</li>
                <li><span className="font-semibold">Stolen</span> – Impound the vehicle and arrest owner/operator. Refer for detailed investigation.</li>
                <li><span className="font-semibold">Valid</span> – Vehicle has no issue.</li>
                <li><span className="font-semibold">Incident</span> – Vehicle is linked to a reported incident. Impound the vehicle. Refer for detailed investigation.</li>
                <li><span className="font-semibold">Smuggled</span> – Impound vehicle. Refer to higher authority for further communication with
                  Nigeria Customs Service.</li>
              </ul>
            </div>

            <div>
              <h2 className="font-bold mb-2">CMR Certificate Status:</h2>
              <ul className="list-disc ml-6">
                <li><span className="font-semibold">Expired</span> – Advise the owner/operator to renew the certificate through their profile via
                  <a href="https://cmris.npf.gov.ng" className="text-blue-600 ml-1">https://cmris.npf.gov.ng</a></li>
                <li><span className="font-semibold">Pending</span> – Application of CMR certificate is undergoing processing (24-72 hours)</li>
                <li><span className="font-semibold">Valid</span> – The CMR certificate is okay</li>
              </ul>
            </div>

            <div>
              <h2 className="font-bold mb-2">Support Centre:</h2>
              <div className="ml-6">
                <p className="font-semibold">Contact Email:</p>
                <ul className="list-disc ml-6">
                  <li><a href="mailto:info@npfcmr.ng" className="text-blue-600">info@npfcmr.ng</a></li>
                  <li><a href="mailto:cmrtech@npfcmr.ng" className="text-blue-600">cmrtech@npfcmr.ng</a></li>
                </ul>
                <p className="font-semibold mt-2">Contact Numbers:</p>
                <ul className="list-disc ml-6">
                  <li className="font-bold text-base">02018870133</li>
                  <li className="font-bold text-base">09169896000</li>
                </ul>
              </div>
            </div>
            <div className="mt-6" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EnforcementGuide;
