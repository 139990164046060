import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="w-full py-2 bg-green-700 text-sm text-white text-center rounded-lg">
                <div className='text-center'>
                    <span className='font-bold'>For support:</span> 02018870133, 08117776698
                </div>

                {/* <p>The Nigeria Police, Department of Operations, Force Headquarters, Abuja</p> */}
                <p>
                    Operational Guidelines on use of
                    <span className="text-red-600 font-bold"> V-Check </span>
                </p>
            </div>      
        </footer>
    )
}
