import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Track if an update is in progress
let isUpdating = false;

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (isUpdating) {
      console.log('Update already in progress; skipping...');
      return; // Prevent multiple updates
    }
    isUpdating = true;
    const waitingWorker = registration.waiting;
    if (waitingWorker) {
      console.log('Applying update silently...');
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      waitingWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          console.log('New service worker activated; reloading...');
          window.location.reload();
        }
      });
    }
  },
  onSuccess: () => {
    isUpdating = false; // Reset after successful caching
    console.log('App is cached and ready for offline use.');
  },
});

reportWebVitals();