import React, { useState, useEffect, useMemo } from "react";
import Nav from "components/Nav";
import { useEnforcerAuth } from "store/EnforcerAuth";
import { checkEnforcerToken } from "components/checkToken";
import { useNavigate } from 'react-router-dom';
import Footer from "components/Footer";
import EnforcerSSE from "components/enforcer/EnforcerSSE";

function Feed() {
  const getAuth = useEnforcerAuth((state) => state.auth);
  const token = getAuth.token;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  }


  return (
    <div className="min-h-screen flex flex-col">
      <Nav />
      <div className="flex-grow">
        <div className="text-xl lg:w-[60%] w-[80%] mx-auto leading-loose">
          <button className="bg-gray-100 hover:bg-gray-200 text-black font-bold py-1 px-4 rounded-lg shadow transition duration-200 ease-in-out flex items-center gap-2 mb-4 mt-4" onClick={goBack}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Go Back
          </button>
          <EnforcerSSE />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Feed;
