import { useEffect, useRef } from "react"; // Added useRef for scrolling
import { useEnforcerFeed } from "store/EnforcerFeed";
import { useEnforcerAuth } from "store/EnforcerAuth";

function EnforcerSSE() {
  const { feed, isConnected, isAudioEnabled, setAudioEnabled } = useEnforcerFeed(
    (state) => ({
      feed: state.feed,
      isConnected: state.isConnected,
      isAudioEnabled: state.isAudioEnabled,
      setAudioEnabled: state.setAudioEnabled,
    })
  );
  const { token } = useEnforcerAuth((state) => ({ token: state.auth.token }));
  const feedRef = useRef(null); // Ref for the feed list

  // Scroll to top when feed updates
  useEffect(() => {
    if (feedRef.current) {
      feedRef.current.scrollTop = 0; // Scroll to top when new item is added
    }
  }, [feed]);

  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-6 md:p-8">
      <div className="w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg p-4 sm:p-6 transition-all duration-300 hover:shadow-xl">
        <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-4 text-center">
          Stolen Vehicle Report Live Feed
        </h2>
        <p
          className={`text-sm sm:text-base mb-4 text-center ${
            isConnected ? "text-green-600" : "text-red-600"
          }`}
        >
          Status: {isConnected ? "Connected" : token ? "Disconnected (Retrying...)" : "Not Logged In"}
        </p>
        {!isAudioEnabled && token && (
          <button
            onClick={() => setAudioEnabled(true)}
            className="w-full sm:w-auto mb-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
          >
            Enable Notification Sound
          </button>
        )}
        {feed.length === 0 ? (
          <p className="text-gray-500 text-center text-sm sm:text-base">
            No updates yet. {token ? "Listening for messages..." : "Please log in to receive updates."}
          </p>
        ) : (
          <ul
            ref={feedRef} // Attach ref to the list
            className="list-none p-0 max-h-96 sm:max-h-96 overflow-y-auto border border-gray-200 rounded-md bg-gray-50"
          >
            {feed.map((item, index) => (
              <li
                key={index}
                className="p-3 border-b border-gray-200 flex flex-col sm:flex-row justify-between items-start sm:items-center text-sm sm:text-base hover:bg-gray-100 transition-colors duration-150"
              >
                <span className="text-gray-800 flex-1 break-words">
                  {item.message}
                </span>
                <span className="text-xs sm:text-sm text-gray-500 mt-1 sm:mt-0 sm:ml-2 shrink-0">
                  {item.timestamp}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default EnforcerSSE;