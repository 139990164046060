import create from "zustand";
import { base_url, channel_connection } from "constants/endpoints";

const useEnforcerFeed = create((set, get) => ({
  feed: JSON.parse(localStorage.getItem("enforcerFeed")) || [],
  isConnected: false,
  isAudioEnabled: false,
  lastError: null, // Track last error for debugging or UI

  updateFeed: (message) =>
    set((state) => {
      const newItem = { message, timestamp: new Date().toLocaleTimeString() };
      const newFeed = [newItem, ...state.feed];
      localStorage.setItem("enforcerFeed", JSON.stringify(newFeed));
      return { feed: newFeed };
    }),

  setConnected: (status) => set({ isConnected: status }),

  setAudioEnabled: (enabled) => set({ isAudioEnabled: enabled }),

  setLastError: (error) => set({ lastError: error }),

  startSSE: (token) => {
    if (!token) {
      console.log("No token available, skipping SSE connection");
      return;
    }

    let controller = new AbortController();
    const notificationSound = new Audio("/notification.wav");
    let retryCount = 0;
    const maxRetries = 5; // Configurable retry limit
    const baseRetryDelay = 2000; // Base delay in ms

    const playNotificationSound = () => {
      if (get().isAudioEnabled) {
        console.log("Playing notification sound");
        notificationSound.play().catch((err) =>
          console.error("Failed to play notification sound:", err)
        );
      }
    };

    const connectSSE = async () => {
      try {
        const url = `${base_url}${channel_connection}enforcer`;
        console.log("Connecting SSE to:", url, "with token:", token);

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "text/event-stream",
          },
          signal: controller.signal,
        });

        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}: ${response.statusText}`);
        }

        set({ isConnected: true, lastError: null });
        console.log("SSE connected successfully");

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            console.log("SSE stream ended by server");
            set({ isConnected: false });
            break;
          }
          const chunk = decoder.decode(value);
          const messages = chunk.split("\n\n");
          messages.forEach((msg) => {
            if (msg.startsWith("data:")) {
              const data = msg.replace("data: ", "").trim();
              console.log("Update received:", data);
              get().updateFeed(data);
              playNotificationSound();
            }
          });
          retryCount = 0; // Reset retries on successful message
        }
      } catch (err) {
        if (controller.signal.aborted) {
          console.log("Connection aborted by cleanup");
          return;
        }
        console.error("SSE connection error:", err.message);
        set({ isConnected: false, lastError: err.message });

        // Reconnect with exponential backoff
        if (retryCount < maxRetries) {
          const delay = baseRetryDelay * Math.pow(2, retryCount); // 2s, 4s, 8s, etc.
          retryCount++;
          console.log(`Reconnecting in ${delay / 1000} seconds (Attempt ${retryCount}/${maxRetries})...`);
          await new Promise((resolve) => setTimeout(resolve, delay));
          if (!controller.signal.aborted) {
            connectSSE();
          }
        } else {
          console.error("Max retries reached, stopping reconnection");
        }
      }
    };

    // Start the connection
    connectSSE();

    // Cleanup function
    return () => {
      console.log("Cleaning up SSE connection");
      controller.abort();
      set({ isConnected: false, lastError: null });
    };
  },
}));

export { useEnforcerFeed };